import PropTypes from 'prop-types';
import NProgress from 'nprogress';
import React, { Component } from 'react';
import Router from 'next/router';

class Progress extends Component {
  timer = null;

  componentDidMount() {
    const { options } = this.props;

    if (options) {
      NProgress.configure(options);
    }

    Router.events.on('routeChangeStart', this.routeChangeStart);
    Router.events.on('routeChangeComplete', this.routeChangeEnd);
    Router.events.on('routeChangeError', this.routeChangeEnd);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    Router.events.off('routeChangeStart', this.routeChangeStart);
    Router.events.off('routeChangeComplete', this.routeChangeEnd);
    Router.events.off('routeChangeError', this.routeChangeEnd);
  }

  routeChangeStart = () => {
    const { showAfterMs } = this.props;
    clearTimeout(this.timer);
    this.timer = setTimeout(NProgress.start, showAfterMs);
  };

  routeChangeEnd = () => {
    clearTimeout(this.timer);
    NProgress.done();
  };

  render() {
    const { color } = this.props;

    return (
      <style jsx global>
        {`
          #nprogress {
            pointer-events: none;
          }
          #nprogress .bar {
            background: ${color};
            height: 4px;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1031;
          }
          #nprogress .peg {
            box-shadow: 0 0 10px ${color}, 0 0 5px ${color};
            display: block;
            height: 100%;
            opacity: 1;
            position: absolute;
            right: 0px;
            -ms-transform: rotate(3deg) translate(0px, -4px);
            -webkit-transform: rotate(3deg) translate(0px, -4px);
            transform: rotate(3deg) translate(0px, -4px);
            width: 100px;
          }
          .nprogress-custom-parent {
            overflow: hidden;
            position: relative;
          }
          .nprogress-custom-parent #nprogress .bar {
            position: absolute;
          }
        `}
      </style>
    );
  }
}
Progress.defaultProps = {
  color: '#e77777',
  showAfterMs: 300,
  options: {},
};

Progress.propTypes = {
  color: PropTypes.string,
  options: PropTypes.shape(),
  showAfterMs: PropTypes.number,
};

export default Progress;
