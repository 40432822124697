/* eslint-disable react/no-array-index-key */
import btoa from 'btoa';
import cc from 'classcat';
import PropTypes from 'prop-types';
import { memo } from 'react';

import { API_CDN, IMG_SERVICE_URL, S3_BUCKET } from 'events/config';
import css from './Image.module.scss';

const cdnUrl = ({ crop, height, src, size, width, url, bucket }) => {
  if (!src) {
    console.error('`src` not provided to Image component');
    return false;
  }

  const request = JSON.stringify({
    bucket,
    key: src.split(url).join(''),
    edits: {
      resize: {
        fit: crop ? 'cover' : 'inside',
        height: crop ? Math.min(size, height) : height,
        width: Math.min(size, width),
      },
    },
  });
  // console.log(`${IMG_SERVICE_URL}${btoa(request)}`);
  return `${IMG_SERVICE_URL}${btoa(request)}`;
};

const Image = (props) => {
  const { alt, className, crop, height, sizes, src, treatment, width } = props;

  if (!src) {
    return false;
  }

  const regexp = /^https:\/\/(.+).s3.amazonaws.com\//g;
  const matches = regexp.exec(src);

  const klass = cc([
    'lazyload',
    className,
    {
      [css.circle]: treatment === 'CIRCLE',
      [css.roundedTop]: treatment === 'ROUNDED_TOP',
      [css.roundedBottom]: treatment === 'ROUNDED_BOTTOM',
    },
  ]);

  const fromTheEventsCDN = src.startsWith(API_CDN);

  if (!matches && !fromTheEventsCDN) {
    return (
      <img
        alt={alt}
        className={klass}
        data-src={src.indexOf('/media') === 0 ? `http://events.ov.com:8001${src}` : src}
        loading="lazy"
        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      />
    );
  }
  const url = fromTheEventsCDN ? API_CDN : matches[0];
  const bucket = fromTheEventsCDN ? S3_BUCKET : matches[1];
  const opts = { crop, height, src, width, url, bucket };

  const sources = sizes.map(({ size, minWidth }) => (
    <source
      key={minWidth}
      srcSet={`${cdnUrl({ ...opts, size })}, ${cdnUrl({ ...opts, size: size * 1.5 })} 2x, ${cdnUrl({
        ...opts,
        size: size * 2,
      })} 3x`}
      media={`(min-width: ${minWidth}px)`}
    />
  ));

  return (
    <picture data-original-src={src}>
      {sources}
      <img
        alt={alt}
        className={klass}
        loading="lazy"
        data-srcset={`${cdnUrl({ ...opts, size: sizes[0].size })}, ${cdnUrl({
          ...opts,
          size: sizes[0].size * 1.5,
        })} 2x, ${cdnUrl({ ...opts, size: sizes[0].size * 2 })} 3x`}
        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      />
    </picture>
  );
};
Image.defaultProps = {
  className: '',
  crop: false,
  height: 10000,
  sizes: [{ size: 768, minWidth: 0 }],
  treatment: false,
  width: 900,
};
Image.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  crop: PropTypes.bool,
  height: PropTypes.number,
  sizes: PropTypes.arrayOf(
    PropTypes.shape({
      minWidth: PropTypes.number,
      size: PropTypes.number,
    }),
  ),
  src: PropTypes.string.isRequired,
  treatment: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  width: PropTypes.number,
};

export default memo(Image);
