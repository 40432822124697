/* eslint-disable max-len, react/prop-types */
const Icon = ({ className = '' }) => (
  <svg className={className} height="36" viewBox="0 0 36 36" width="36">
    <title>X</title>
    <path
      d="M19.414 18l4.243 4.243a1 1 0 0 1-1.414 1.414L18 19.414l-4.243 4.243a1 1 0 0 1-1.414-1.414L16.586 18l-4.243-4.243a1 1 0 0 1 1.414-1.414L18 16.586l4.243-4.243a1 1 0 0 1 1.414 1.414L19.414 18z"
      fillRule="evenodd"
    />
  </svg>
);
export default Icon;
