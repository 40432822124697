import cc from 'classcat';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Link } from 'events/routes';
import css from './index.module.scss';

const Button = (props) => {
  const {
    alert,
    big,
    buttonIsh,
    bordered,
    borderedHover,
    borderedInverseHover,
    children,
    className,
    disabled,
    full,
    gray,
    href,
    on,
    iconLeft,
    iconRight,
    linkStyle,
    medium,
    params,
    route,
    small,
    type,
    white,
  } = props;
  const attrs = Object.keys(Button.defaultProps);
  const buttonProps = { ...props };
  const moduleClass = cc([
    css.root,
    className,
    {
      [css.alert]: alert,
      [css.big]: big,
      [css.bordered]: bordered,
      [css.borderedHover]: borderedHover,
      [css.borderedInverseHover]: borderedInverseHover,
      [css.disabled]: disabled,
      [css.full]: full,
      [css.gray]: gray,
      [css.iconLeft]: iconLeft,
      [css.iconRight]: iconRight,
      [css.linkStyle]: linkStyle,
      [css.medium]: medium,
      [css.small]: small,
      [css.on]: on,
      [css.white]: white,
    },
  ]);
  attrs.forEach((attr) => {
    delete buttonProps[attr];
  });

  if (buttonIsh) {
    return (
      <div className={moduleClass} {...buttonProps}>
        {iconLeft}
        {children}
        {iconRight}
      </div>
    );
  }

  if (route) {
    return (
      <Link route={route} params={params}>
        <a className={moduleClass} {...buttonProps}>
          {iconLeft}
          {children}
          {iconRight}
        </a>
      </Link>
    );
  }

  if (href) {
    return (
      <a className={moduleClass} href={href} {...buttonProps}>
        {iconLeft}
        {children}
        {iconRight}
      </a>
    );
  }

  if (disabled) {
    buttonProps['aria-disabled'] = disabled;
  }

  /* eslint-disable react/button-has-type */
  return (
    <button className={moduleClass} disabled={disabled} type={type} {...buttonProps}>
      {iconLeft}
      {children}
      {iconRight}
    </button>
  );
};
Button.defaultProps = {
  alert: false,
  big: false,
  bordered: false,
  borderedHover: false,
  borderedInverseHover: false,
  buttonIsh: false,
  className: '',
  disabled: false,
  full: false,
  gray: false,
  href: false,
  on: false,
  iconLeft: false,
  iconRight: false,
  linkStyle: false,
  medium: false,
  params: false,
  route: false,
  small: false,
  type: 'button',
  white: false,
};
Button.propTypes = {
  alert: PropTypes.bool,
  big: PropTypes.bool,
  bordered: PropTypes.bool,
  borderedHover: PropTypes.bool,
  borderedInverseHover: PropTypes.bool,
  buttonIsh: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.node]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  full: PropTypes.bool,
  gray: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  iconLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.node]),
  iconRight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.node]),
  linkStyle: PropTypes.bool,
  params: PropTypes.oneOfType([PropTypes.shape(), PropTypes.bool]),
  medium: PropTypes.bool,
  on: PropTypes.bool,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  small: PropTypes.bool,
  type: PropTypes.string,
  white: PropTypes.bool,
};
export default memo(Button);
