import cc from 'classcat';
import PropTypes from 'prop-types';
import { PureComponent, createRef } from 'react';

import clickEvent from 'events/utils/dom/clickEvent';
import isVisible from 'events/utils/dom/isVisible';
import { KEY_CODES, KEY_DOWN, RESIZE, SCROLL } from 'events/config';
import Button from '../Button';
import ChevronDown from '../icons/ChevronDown';
import css from './SelectStyled.module.scss';
import Options from './Options';

const getLabel = (options, value) => {
  const option = options.find((opt) => opt.value === value);
  if (!option) {
    return false;
  }

  return option.label;
};

class SelectStyled extends PureComponent {
  constructor(props) {
    super(props);
    this.buttonRef = createRef();
    this.optionsRef = createRef();
    this.interval = {};

    this.state = {
      flip: false,
      left: 0,
      mounted: false,
      open: false,
      top: 0,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  componentWillUnmount() {
    this.close();
    this.onBlur();
    clearInterval(this.interval);
  }

  onFocus = () => {
    const { onFocus: onFocusProp } = this.props;
    if (typeof onFocusProp === 'function') {
      onFocusProp();
    }

    document.addEventListener(KEY_DOWN, this.onKeyDownFocused, false);
  };

  onBlur = () => {
    document.removeEventListener(KEY_DOWN, this.onKeyDownFocused);
  };

  onKeyDownFocused = (event) => {
    switch (event.key) {
      case KEY_CODES.ARROW_DOWN:
      case KEY_CODES.ARROW_UP:
        event.preventDefault();
        this.open();
        break;
      default:
        break;
    }
  };

  onKeyDown = (event) => {
    const { ARROW_DOWN, ARROW_LEFT, ARROW_RIGHT, ARROW_UP, ESC, TAB } = KEY_CODES;
    switch (event.key) {
      case ESC:
        this.close();
        break;
      case ARROW_LEFT:
      case ARROW_UP:
        event.preventDefault();
        this.focusOption(-1);
        break;
      case ARROW_DOWN:
      case ARROW_RIGHT:
        event.preventDefault();
        this.focusOption(1);
        break;
      case TAB:
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  onDraw = () => {
    const buttonRef = this.buttonRef.current;
    if (!isVisible(buttonRef)) {
      this.close();
      return;
    }
    this.setState(this.getPosition());
  };

  getPosition = () => {
    const buttonRef = this.buttonRef.current;
    const optionsRef = this.optionsRef.current;

    const tar = 12;
    const { left: buttonLeft, top: buttonTop } = buttonRef.getBoundingClientRect();
    const buttonWidth = buttonRef.offsetWidth;
    const buttonHeight = buttonRef.offsetHeight;

    const optionsWidth = optionsRef.offsetWidth;
    const optionsHeight = optionsRef.offsetHeight;

    const flip = buttonTop + buttonHeight + optionsRef.offsetHeight > window.innerHeight;

    const optionsLeft = buttonLeft + buttonWidth / 2 - optionsWidth / 2;
    const optionsTop = flip ? buttonTop - optionsHeight - 12 : buttonTop + buttonHeight + 12;

    const left = Math.round(Math.max(tar, Math.min(optionsLeft, window.innerWidth - optionsWidth - tar)));
    const top = Math.max(Math.min(optionsTop, window.innerHeight - optionsHeight), 0);
    return { flip, left, top };
  };

  close = () => {
    // console.info('close');
    document.removeEventListener(KEY_DOWN, this.onKeyDown);
    document.removeEventListener(SCROLL, this.onDraw);
    document.querySelector('html').removeEventListener(clickEvent(), this.close);
    window.removeEventListener(RESIZE, this.onDraw);

    this.setState({ open: false });
    if (this.buttonRef.current) {
      this.buttonRef.current.querySelector('button').focus();
    }
  };

  open = () => {
    document.addEventListener(KEY_DOWN, this.onKeyDown, false);
    document.addEventListener(SCROLL, this.onDraw, { passive: true });
    document.querySelector('html').addEventListener(clickEvent(), this.close, false);
    window.addEventListener(RESIZE, this.onDraw, { passive: true });
    this.setState({
      ...this.getPosition(),
      open: true,
    });
    clearInterval(this.interval);
    this.interval = setTimeout(this.focus, 150);
  };

  focus = () => {
    const { current } = this.optionsRef;
    const selected = current.querySelector('[aria-selected="true"]');
    const target = selected || current.querySelector('a,button:not(:disabled)');
    target.focus();
  };

  focusOption = (dir) => {
    const buttons = this.optionsRef.current.querySelectorAll('a,button:not(:disabled)');
    const items = Array.from(buttons);
    const index = Math.max(0, Math.min(items.indexOf(document.activeElement) + dir, items.length - 1));
    items[index].focus();
  };

  select = (value) => {
    const { label, name, onChange } = this.props;
    onChange({ target: { name, value, label } });
    this.close();
  };

  toggle = (event) => {
    const { open } = this.state;
    event.stopPropagation();
    if (open) {
      this.close();
    } else {
      this.open();
    }
  };

  getLabel = () => {
    const { displayLabel, options, placeholder, value } = this.props;

    if (displayLabel) {
      return displayLabel;
    }

    const label = getLabel(options, value);
    if (value === false || value === '' || !label) {
      return placeholder;
    }

    return <>{label}</>;
  };

  render() {
    const { buttonProps, className, disabled, hideLabel, label, name, options, withChevron, value } = this.props;
    const { flip, left, mounted, open, top } = this.state;
    return (
      <label className={cc([css.root, { [css.open]: open }, className])} htmlFor={`select-${name}`}>
        <div className={cc([css.label, { [css.adaHidden]: hideLabel }])}>{label}</div>
        <>
          <div className={css.button} ref={this.buttonRef}>
            <Button
              {...buttonProps}
              aria-expanded={open ? 'true' : 'false'}
              className={cc([css.buttonLabel, { [css.withoutChevron]: !withChevron }])}
              disabled={disabled}
              onBlur={this.onBlur}
              onClick={this.toggle}
              onFocus={this.onFocus}
              title={label}
            >
              {this.getLabel()}
              {withChevron && <ChevronDown />}
            </Button>
          </div>
          {mounted && (
            <Options
              flip={flip}
              left={left}
              onChange={this.select}
              open={open}
              options={options}
              ref={this.optionsRef}
              top={top}
              value={value}
            />
          )}
        </>
      </label>
    );
  }
}

SelectStyled.defaultProps = {
  buttonProps: { gray: true },
  className: '',
  disabled: false,
  displayLabel: false,
  hideLabel: false,
  onFocus: () => {},
  placeholder: 'Select',
  value: false,
  withChevron: true,
};

SelectStyled.propTypes = {
  buttonProps: PropTypes.shape(),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  displayLabel: PropTypes.oneOfType([PropTypes.bool, PropTypes.node, PropTypes.string]),
  hideLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.bool, PropTypes.number, PropTypes.string]),
  withChevron: PropTypes.bool,
};

export default SelectStyled;
