import jwtDecode from 'jwt-decode';

import { getAccessToken } from '../graphql/accessToken';

const getUserFromJWT = () => {
  const token = getAccessToken();
  try {
    const { avatar, firstName, lastName, username } = jwtDecode(token);
    return { avatar, firstName, lastName, username };
  } catch {
    return { avatar: null, firstName: null, lastName: null, username: null };
  }
};

export default getUserFromJWT;
