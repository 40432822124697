const routes = require('next-routes');

// Name   Pattern    Page
module.exports = routes()
  .add('healthz')
  .add('readiness')
  .add('reset-password', '/reset-password', 'reset-password/index')
  .add('forgot-password', '/reset-password/forgot-password', 'reset-password/forgot-password')
  .add('verify-account', '/registration/verify-account', 'registration/verify-account')
  .add('registration-activation', '/registration/activation', 'registration/activation')
  .add('login-signup', '/account/:slug(login|sign-up)', 'account/index')
  .add('account', '/account/:slug', 'account/profile')
  .add('redirect', '/event/:id')
  .add('/events/:marketSlug/:slug', 'event')
  .add('/events/:marketSlug/:slug/:date', 'event')
  .add('adminEventNew', '/coach/events/new/:step', 'admin/events/new')
  .add('adminEvents', '/coach/events', 'admin/events/index')
  .add('adminEventEdit', '/coach/events/:id/edit/:step', 'admin/events/edit')
  .add('admin', '/coach', 'admin/index')
  .add('pride', '/pride', 'pride')
  .add('market', '/:slug', 'index');
// .add('covid', '/:slug', 'index');
