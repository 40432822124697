import cc from 'classcat';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { createPortal } from 'react-dom';

import css from './SelectStyled.module.scss';
import Button from '../Button';
import Check from '../icons/Check';

/* eslint-disable react/no-array-index-key */
const Options = forwardRef(({ flip, left, onChange, open, options, top, value }, ref) =>
  createPortal(
    <div
      aria-hidden={open ? 'false' : 'true'}
      className={cc([css.options, { [css.flip]: flip, [css.on]: open }])}
      ref={ref}
      style={{ left, top }}
    >
      <div className={css.optionsList} role="listbox" tabIndex={open ? 0 : -1}>
        {options.map((option, idx) => {
          const buttonProps = {
            'aria-label': option.ariaLabel ? option.ariaLabel : `Select ${option.label}`,
            className: cc([css.optionButton, { [css.optionButtonDisabled]: option.disabled }]),
            disabled: option.disabled,
            onClick: () => {
              onChange(option.value);
            },
            params: option.params,
            route: option.route,
            role: 'option',
            href: option.href,
            tabIndex: open ? 0 : -1,
          };
          if (option.value === value) {
            buttonProps['aria-selected'] = true;
          }
          return (
            <Button {...buttonProps} key={`${option.value}-${idx}`} type="button">
              {option.label}
              {option.value === value && <Check className={css.optionIcon} />}
            </Button>
          );
        })}
      </div>
    </div>,
    document.body,
  ),
);
Options.defaultProps = {
  value: false,
};
Options.propTypes = {
  flip: PropTypes.bool.isRequired,
  left: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  top: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.bool, PropTypes.number, PropTypes.string]),
};
export default Options;
