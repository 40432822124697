/* eslint-disable react/prop-types */
const Icon = ({ className = '' }) => (
  <svg className={className} width="36" height="36" viewBox="0 0 36 36">
    <title>Checkmark</title>
    <path
      d="M12.586 24l-2.829-2.828 1.415-1.415L14 22.586l11.314-11.314 1.414 1.414L14 25.414 12.586 24z"
      fillRule="evenodd"
    />
  </svg>
);
export default Icon;
